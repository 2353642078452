// extracted by mini-css-extract-plugin
export var label = "nutrition-facts-label-module--label--3qDX-";
export var fontMd = "nutrition-facts-label-module--font-md--c1N-x";
export var fontBlack = "nutrition-facts-label-module--font-black--MxTaI";
export var fontBlackMd = "nutrition-facts-label-module--font-black-md--2ngQF nutrition-facts-label-module--font-black--MxTaI";
export var fontBlackLg = "nutrition-facts-label-module--font-black-lg--2L3AH nutrition-facts-label-module--font-black--MxTaI";
export var title = "nutrition-facts-label-module--title--3WME4 nutrition-facts-label-module--font-black-lg--2L3AH nutrition-facts-label-module--font-black--MxTaI";
export var row = "nutrition-facts-label-module--row--1ojbO";
export var rowBorderBottom = "nutrition-facts-label-module--row-border-bottom--1Ytbk nutrition-facts-label-module--row--1ojbO";
export var rowBorderBottomThick = "nutrition-facts-label-module--row-border-bottom-thick--2L_Hn nutrition-facts-label-module--row-border-bottom--1Ytbk nutrition-facts-label-module--row--1ojbO";
export var rowBorderBottomThicker = "nutrition-facts-label-module--row-border-bottom-thicker--1HGsV nutrition-facts-label-module--row-border-bottom--1Ytbk nutrition-facts-label-module--row--1ojbO";
export var rowJustifyEndBorderBottom = "nutrition-facts-label-module--row-justify-end-border-bottom--3qO6g nutrition-facts-label-module--row--1ojbO";
export var rowAlignEndBorderBottomThick = "nutrition-facts-label-module--row-align-end-border-bottom-thick--1Jzrr nutrition-facts-label-module--row-border-bottom-thick--2L_Hn nutrition-facts-label-module--row-border-bottom--1Ytbk nutrition-facts-label-module--row--1ojbO";
export var dailyValueFootnote = "nutrition-facts-label-module--daily-value-footnote--1PhvQ nutrition-facts-label-module--row--1ojbO";
export var indent1 = "nutrition-facts-label-module--indent-1--2fulE";
export var indent2 = "nutrition-facts-label-module--indent-2--1dCXF";
export var marginIndent2 = "nutrition-facts-label-module--margin-indent-2--OQcpL";
export var totalSugars = "nutrition-facts-label-module--total-sugars--2hyKo";
export var marginLeft = "nutrition-facts-label-module--margin-left--2L6qU";
export var paddingTop0 = "nutrition-facts-label-module--padding-top-0--QZGTe";
export var paddingBottom0 = "nutrition-facts-label-module--padding-bottom-0--2nB6w";